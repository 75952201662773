export default {
    login: {
        title: "Bienvenue\u00a0!",
        user: "Nom d'utilisateur ou adresse courriel",
        password: "Mot de passe",
        forgotten: "Mot de passe oublié\u00a0?",
        cookie: "Se souvenir de moi",
        button: "Se connecter",
        passwordSent: "Le courriel a bien été envoyé.",
        forgottenPassword: {
            title: "Mot de passe oublié",
            text: "Saisissez l’adresse courriel associée au compte et une demande de changement de mot de passe y sera envoyée.",
            email: "Adresse courriel",
            button: "Réinitialiser le mot de passe",
            error: "Veuillez entrer l'adresse courriel"
        },
        newPassword: {
            title: "Nouveau mot de passe",
            text: "Veuillez entrer le nouveau mot de passe",
            label: "Nouveau mot de passe",
            button: "Confirmer",
            error: "Les deux mots de passe doivent être identiques",
            empty: "Veuillez entrer le mot de passe deux fois",
            serverError: "Une erreur est survenue. Veuillez réessayer plus tard."

        }
    },
    home: {
        title: "Bonjour {name}\u00a0!",
        general: "MESSAGE D'INTÉRÊT GÉNÉRAL",
        personal: "Message pour {name}",
        punch: "Puncher votre temps pour la journée",
        run: "Accéder à votre run",
        logout: "Se déconnecter",
        newNoteBegin: "Vous avez",
        newNoteAccent: "{nb} note | {nb} notes",
        newNoteEnd: "non-lue | non-lues"
    },
    punchs: {
        title: "{start} au {end} {month}",
        total: {
            week: "Total de la semaine",
            day: "Total de la journée"
        },
        noTime: "Aucun temps inscrit pour cette journée jusqu'à maintenant",
        start: "Débuter le temps",
        stop: "Arrêter le temps"
    },
    notes: {
        see: "Voir",
        read: "Marquer comme lu",
        unread: "Marquer comme non lu",
        noNotes: "Aucune note associée à cette run"
    },
    runs: {
        all: "Toutes",
        todo: "À faire",
        search: "Rechercher un client ou une adresse",
        new: "Nouveau",
        late: "En retard",
        completed: "Tâches complétées",
        end: "Fin de la liste",
        popup: "La ou les tâches suivantes ont été annulées pour cette semaine. Elles ont été retirées de votre run jusqu’à leur prochaine occurrence.",
        loading: "Chargement..."
    },
    client: {
        tasks: "TÂCHES À COMPLÉTER",
        done: "Tâche complétée",
        todo: "Marquer comme complétée",
        signature: "Signez sur la ligne avec votre doigt",
        signatureError: "La signature est invalide.",
        horizontal: "Veuillez mettre votre téléphone en mode horizontal",
        addNote: "Écrire une note",
        submitNote: "Envoyer la note",
        noteSubmitted: "Note envoyée",
        note: "Note:",
        frequency: "Fréquence:",
        lastOne: "Dernière exécution:",
        price: "Prix:",
        signatureNeeded: "Ces tâches nécessitent une signature",
        hasNotifications: "Ces tâches envoient une notification à la complétion",
        selectedTask: "Tâche sélectionnée",
        selectTask: "Sélectionner cette tâche",
        selectAll: "Marquer tout comme complété",
        selectAllRemaining: "Marquer les tâches restantes complétées",
        sign: "Marquer {nb} tâche comme complétée | Marquer {nb} tâches comme complétées",
        isSigning: "En cours de signature"
    },
    nav: {
        home: "Accueil",
        punchs: "Punchs",
        notes: "Notes",
        runs: "Runs"
    }
}
